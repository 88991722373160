import { Component, computed, inject, signal, viewChild } from "@angular/core"
import { Router } from "@angular/router"
import { TourService } from "./tour.service"
import { TourAudioPlayerComponent } from "./tour-audio-player.component"
import { ProfileService } from "../profile/profile.service"
import { AudioStatus } from "./tour.model"
import { faChevronLeft, faChevronRight, faClose } from "@fortawesome/pro-solid-svg-icons"
import { TourEditItemsComponent } from "./tour-edit-items.component"
import { UiInheritDirective } from "ui/inherit"
import { PageToolbarService } from "../page-toolbar.service"
import { UiIconComponent } from "ui/icon"

@Component({
  imports: [
    TourAudioPlayerComponent,
    UiInheritDirective,
    UiIconComponent,
  ],
  standalone: true,
  template: `
<!--
    @defer (when isAdmin()) {
      <div (click)="$event.stopPropagation()">
        @if (showTourEditor()) {
          <e2e-tour-edit-items
            class="text-secondary"
            [activeIndexInput]="[currentItemIndex()]"
            (closeTourEditor)="setShowTourEditor(false)"
          />
        }
      </div>
    }
-->

    @if (showTour()) {
      <!--
            <lib-ui-dialog
              (cancelAndClose)="setShowTour(false)"
              [scrollableBackground]="true"
              [transparentBackdrop]="true"
              position="top"
              [offset]="pageToolbarHeight() + 10"
            >
      -->
        <!--
                <div
                  ngProjectAs="content"
                  class="row"
                  style="align-items: flex-start; padding: 10px"
                >
        -->
      <div class="row">
        <button
          class="btn btn-sm blue"
          (click)="setShowTour(false)"
        >
          <lib-ui-icon [icon]="faClose"/>
        </button>

        <div
          class="column"
          style="flex: 1; align-items: center"
        >
          <div
            class="row"
            style="align-items: stretch; gap: 0; flex-wrap: nowrap"
          >
            <div
              class="row"
              style="justify-content: center; cursor: pointer; padding: 0 20px"
              (click)="changeToPreviousItem()"
            >
              <lib-ui-icon
                [icon]="faChevronLeft"
                [style.opacity]="currentItemIndex() > 0 ? 1 : 0.3"
              />
            </div>

            <e2e-tour-audio-player
              #tourAudioPlayer
              [item]="currentItem()"
              [itemIndex]="currentItemIndex()"
              (changeToNextItem)="changeToNextItem()"
              (changeToNextSegment)="changeToNextSegment()"
              (changeAudioStatus)="changeAudioStatus($event)"
              inherit
            />

            <div
              class="row"
              style="justify-content: center; cursor: pointer; padding: 0 20px"
              (click)="changeToNextItem()"
            >
              <lib-ui-icon
                [icon]="faChevronRight"
                [style.opacity]="currentItemIndex() < tourItems().length - 1 ? 1 : 0.3"
              />
            </div>

          </div>
          <div (click)="clickTitle()">
            {{ currentItem()?.sectionTitle }}
            {{ currentItem()?.title }}
          </div>

        </div>
      </div>
      <!--
                   </div>
           -->

        <!--
              </lib-ui-dialog>
        -->
    }
  `,
  selector: "e2e-tour",
})
export class TourComponent {
  private router = inject(Router)
  private tourService = inject(TourService)
  private profileService = inject(ProfileService)
  private pageToolbarService = inject(PageToolbarService)

  audioStatus = signal<AudioStatus>(AudioStatus.pause)
  audioStatusMessage = computed(() => (this.audioStatus() === "playing" ? "Pause the Tour" : "Resume the Tour"))
  currentItemIndex = this.tourService.currentItemIndex
  currentSegmentIndex = this.tourService.currentSegmentIndex
  previousItemIndex = computed(() => (this.currentItemIndex() ? this.currentItemIndex() - 1 : 0))
  nextItemIndex = computed(() =>
    this.currentItemIndex() < this.tourItems().length - 1 ? this.currentItemIndex() + 1 : this.tourItems().length,
  )
  currentItem = computed(() => this.tourItems_map().get(this.currentItemIndex()))
  previousItem = computed(() => this.tourItems_map().get(this.previousItemIndex()))
  nextItem = computed(() => this.tourItems_map().get(this.nextItemIndex()))
  interval = signal(0)
  showTourEditor = this.tourService.showTourEditor
  showTour = this.tourService.showTour
  tourItems = this.tourService.tourItems
  tourItems_map = this.tourService.tourItems_map
  // trackDuration = signal(0)
  isAdmin = this.profileService.isAdmin
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight
  private tourAudioPlayer = viewChild<TourAudioPlayerComponent>("tourAudioPlayer")

  changeToPreviousItem() {
    // console.log("changeToPreviousItem", this.previousItemIndex())
    this.tourService.setCurrentItemIndex(this.previousItemIndex())
    this.navigateToSection()
  }

  changeToNextItem() {
    // console.log("changeToNextItem", this.nextItemIndex())
    this.tourService.setCurrentItemIndex(this.nextItemIndex())
    this.navigateToSection()
  }

  navigateToSection() {
    const segment = this.tourItems()[this.currentItemIndex()].segments[this.tourService.currentSegmentIndex()]
    // console.log(segment)
    if (!segment) return
    const route = segment.section
      .toLowerCase()
      .replace("_", "-") || "introduction"
    // const routeArray = [route]
    const anchor = segment.anchor
    if (anchor) {
      // switch (anchor) {
      //   case "grade_d":
      //     routeArray.push("#grade-d")
      //     break
      // }
      this.tourService.scrollToElement(anchor)
    }
    // console.log(routeArray.join(""))
    this.router.navigate([route], {})
  }

  changeToNextSegment() {
    // console.log("changeToNextSegment")
    this.navigateToSection()
  }

  changeAudioStatus(audioStatus: AudioStatus) {
    // console.log("changeAudioStatus", audioStatus)
    this.audioStatus.set(audioStatus)
  }

  exitTour(event: Event) {
    event.stopPropagation()
    this.setShowTour(false)
  }

  clickTitle() {
    // console.log("clickTitle")
    const tourAudioPlayer = this.tourAudioPlayer()
    if (this.isAdmin() && tourAudioPlayer) {
      tourAudioPlayer.pause()
      this.setShowTourEditor(true)
      // this.tourService.setShowTour(false)
    }
  }

  setShowTour(showTour: boolean) {
    this.tourService.setShowTour(showTour)
    // if (showTour) {
    //   this.load(this.currentItemIndex())
    // }
  }

  setShowTourEditor(showTourEditor: boolean) {
    this.tourService.setShowTourEditor(showTourEditor)
  }

  protected readonly faChevronRight = faChevronRight
  protected readonly faChevronLeft = faChevronLeft
  protected readonly faClose = faClose

}
