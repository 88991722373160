import { Component, input, output } from "@angular/core"

@Component({
  imports: [],
  standalone: true,
  template: `
    <div
      tabindex="0"
      class="collapse border border-base-300 bg-base-200"
      [style.border-bottom-left-radius.px]="last() ? undefined : 0"
      [style.border-bottom-right-radius.px]="last() ? undefined : 0"
      [style.border-top-left-radius.px]="first() ? undefined : 0"
      [style.border-top-right-radius.px]="first() ? undefined : 0"
      [style.border-bottom]="last() ? undefined : 'none'"
      (focus)="activated.emit(index())"
      (click)="activated.emit(index())"
    >
      <input type="checkbox"/>
      <div class="collapse-title row-nowrap">
<!--      <div class="collapse-title text-xl font-medium row-nowrap">-->
        <div>
          <ng-content select="title-left"/>
        </div>
        <div style="margin-left: auto">
          <ng-content select="title-right"/>
        </div>
      </div>
      <div class="collapse-content">
        <ng-content select="content"/>
      </div>
    </div>

    <!--
        @if (false) {
          @for (item of []; track index; let index = $index; let first = $first; let last = $last) {
            <e2e-collapse
              [first]="first"
              [last]="last"
              [index]="index"
              (activated)="activated.emit($event)"
            >
    
              <ng-container ngProjectAs="title-left">
                TITLE LEFT
              </ng-container>
    
              <ng-container ngProjectAs="title-right">
                TITLE RIGHT
              </ng-container>
    
              <ng-container ngProjectAs="content">
                CONTENT
              </ng-container>
    
            </e2e-collapse>
          }
        }
    -->
  `,
  selector: "lib-ui-collapse",
})
export class UiCollapseComponent {
  first = input(false)
  last = input(false)
  index = input(-1)
  activated = output<number>()
}
