import {
  Component, computed,
  ElementRef,
  EventEmitter,
  inject,
  input,
  OnDestroy,
  Output,
  Renderer2,
  viewChild,
} from "@angular/core"
import { environment } from "../../../environments/environment"
import { toObservable } from "@angular/core/rxjs-interop"
import { filter, tap } from "rxjs/operators"
import { AsyncPipe } from "@angular/common"
import { combineLatest, map } from "rxjs"
import { AudioStatus, TourItem } from "./tour.model"
import { TourService } from "./tour.service"

@Component({
  imports: [AsyncPipe],
  standalone: true,
  template: `
    <!--
        @if (item$ | async) {} doesn't control any view rendering
        Its purpose is to ensure the item$ observable is subscribed and processed
    -->
    @if (item$ | async) {
    }
    <audio
      #audioElement
      controls="controls"
      (playing)="setAudioStatus($event.type)"
      (pause)="setAudioStatus($event.type)"
      (ended)="nextItem()"
    >
      Your browser does not support the <code>audio</code> tour.
    </audio>
  `,
  selector: "e2e-tour-audio-player",
})
export class TourAudioPlayerComponent implements OnDestroy {
  private renderer2 = inject(Renderer2)
  private tourService = inject(TourService)

  item = input<TourItem>()
  itemIndex = input(0) // triggers observable
  private elementRef = viewChild<ElementRef<HTMLAudioElement>>("audioElement")
  interval = computed(() =>
    // lookup next segment timestamp
    this.item()?.segments[this.tourService.currentSegmentIndex() + 1]?.timestamp || 0
  )

  @Output() changeAudioStatus = new EventEmitter<AudioStatus>()
  @Output() changeToNextItem = new EventEmitter<void>()
  @Output() changeToNextSegment = new EventEmitter<void>()

  item$ = combineLatest([toObservable(this.elementRef), toObservable(this.itemIndex)]).pipe(
    map(
      ([audioElement, itemIndex]) =>
        [audioElement?.nativeElement, itemIndex] as [HTMLAudioElement | undefined, number],
    ),
    filter((args): args is [HTMLAudioElement, number] => Boolean( args[0] && this.item())),
    tap(([nativeElement]) => this.load(nativeElement)),
  )

  // audioStatus = signal<string>("pause")
  // audioStatusMessage = computed(() => (this.audioStatus() === "playing" ? "Pause the Tour" : "Resume the Tour"))

  ngOnDestroy() {
    this.elementRef()?.nativeElement.removeEventListener("timeupdate", this.timeUpdateListener)
  }

  nextItem() {
    this.changeToNextItem.emit()
  }

  nextSegment() {
    // console.log("nextSegment")
    this.tourService.setCurrentItemIndex(this.tourService.currentSegmentIndex() + 1)
    this.changeToNextSegment.emit()
  }

  setAudioStatus(audioStatus: string) {
    switch (audioStatus) {
      case "playing":
      case "pause":
        this.changeAudioStatus.emit(audioStatus as AudioStatus)
    }
  }

  pause() {
    this.elementRef()?.nativeElement.pause()
  }

  play() {
    this.elementRef()?.nativeElement.play()
  }

  load(nativeElement: HTMLAudioElement) {
    const item = this.item()
    if (!item) return
    // console.log("load", item)
    nativeElement.pause()
    nativeElement.removeEventListener("timeupdate", this.timeUpdateListener)
    const audioFilePath = this.audioFilePath(item)
    if (audioFilePath) {
      this.renderer2.setAttribute(nativeElement, "src", audioFilePath)
      this.renderer2.setAttribute(nativeElement, "type", "audio/mp3")
      nativeElement.load()
      nativeElement
        .play()
        .then(() => {
          nativeElement.addEventListener("timeupdate", this.timeUpdateListener)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }

  audioFilePath(item: TourItem) {
    return (
      "https://storage.googleapis.com/" +
      environment.firebaseConfig.storageBucket +
      "/tour-audio/" +
      item.id.toLowerCase() +
      ".mp3" || ""
    )
  }

  timeUpdateListener = () => {
    const nativeElement = this.elementRef()?.nativeElement
    console.log("timeUpdateListener", nativeElement?.currentTime, this.interval())
    if (nativeElement && this.interval() && nativeElement.currentTime >= this.interval()) {
      this.nextSegment()
    }
  }
}
