import { computed, effect, inject, Injectable, signal, untracked } from "@angular/core"
import { ActivatedRoute, NavigationEnd, Params, Router, RouterEvent } from "@angular/router"
import { RegionEnum } from "../regions/region.model"

export const defaultRegion = RegionEnum.BALTIMORE_MD

/**
 * these values are used by the router
 */
export interface QueryParams extends Params {
  debug?: string
  p?: // primaryDialog
    | ""
    | "content-moderation"
    | "my-account-login"
    | "my-account-content"
    | "my-account-profile"
    | "content-manager"
  s?: // secondaryDialog
    | ""
}

export interface QueryParamsList {
  contentManager: QueryParams
  contentModeration: QueryParams
  myAccountContent: QueryParams
  myAccountProfile: QueryParams
  myAccountLogin: QueryParams
}

export enum RoutePath {
  INTRODUCTION = "introduction",
  TIMELINE = "timeline",
  REDLINE_MAP = "redline-map",
  STORIES_MAP = "stories-map",
  UNDESIGN = "undesign",
  USERS = "users",
  DOCUMENTATION = "documentation",
}

export enum Section {
  INTRODUCTION = "INTRODUCTION",
  TIMELINE = "TIMELINE",
  MODERATION = "MODERATION",
  MANAGER = "MANAGER",
  MY_CONTENT = "MY_CONTENT",
  PROFILE = "PROFILE",
  LOGIN = "LOGIN",
  REDLINE_MAP = "REDLINE_MAP",
  STORIES_MAP = "STORIES_MAP",
  UNDESIGN = "UNDESIGN",
}

// const sections = Object.keys(Section) as Section[]

export type SectionFlags = {
  [key in Section]: boolean
}

@Injectable({
  providedIn: "root",
})
export class RouteService {
  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)

  private _routeHasPageToolbar = signal(false)
  routeHasPageToolbar = this._routeHasPageToolbar.asReadonly()

  private _debug = signal(false)
  readonly debug = this._debug.asReadonly()
  private _region = signal(defaultRegion)
  readonly region = this._region.asReadonly()
  regionIsSet = signal(false)

  timelineIdFromUrl = computed(() =>
    this.fragments()[0] === "view" && this.fragments()[1] || "")
  editorIdFromUrl = computed(() =>
    this.fragments()[0] === "edit" && this.fragments()[1] || "")

  private _underwritingManual = signal<boolean>(false)
  readonly underwritingManual = this._underwritingManual.asReadonly()
  private _contentManager_dialog = signal<boolean>(false)
  readonly contentManager_dialog = this._contentManager_dialog.asReadonly()
  private _contentModeration_dialog = signal<boolean>(false)
  readonly contentModeration_dialog = this._contentModeration_dialog.asReadonly()
  private _fragments = signal<string[]>(["", "", "", ""])
  readonly fragments = this._fragments.asReadonly()
  private _myAccountTabIndex = signal(0)
  readonly myAccountTabIndex = this._myAccountTabIndex.asReadonly()
  private _url = signal("")
  readonly url = this._url.asReadonly()
  private _queryParams = signal<QueryParams>({})
  readonly queryParams = this._queryParams.asReadonly()

  private _section = signal<Section | undefined>(undefined)
  readonly section = computed(() => {
    const sectionFlags = {} as SectionFlags
    Object.keys(Section).forEach((sectionKey) => {
      sectionFlags[sectionKey as keyof typeof Section] =
        this._section() === Section[sectionKey as keyof typeof Section]
    })
    return sectionFlags
  })

  private _activatedRoute_queryParams = signal<QueryParams>({})
  private _router_event = signal<RouterEvent | undefined>(undefined)
  private _activatedRoute_fragment = signal<string | null | undefined>(undefined)

  queryParamsList = computed<QueryParamsList>(() => {
    const queryParams = this.queryParams()
    return {
      contentManager: {
        ...queryParams,
        p: "content-manager",
        s: "",
      },
      contentModeration: {
        ...queryParams,
        p: "content-moderation",
        s: "",
      },
      myAccountContent: {
        ...queryParams,
        p: "my-account-content",
        s: "",
      },
      myAccountProfile: {
        ...queryParams,
        p: "my-account-profile",
        s: "",
      },
      myAccountLogin: {
        ...queryParams,
        p: "my-account-login",
        s: "",
      },
    }
  })

  constructor() {
    this.subscribeToRouter_events()
    this.subscribeToActivatedRoute_fragment()
    this.subscribeToActivatedRoute_queryParams()

    effect(() => {
      const routerEvent = this._router_event()
      const activatedRoute_fragment = this._activatedRoute_fragment()
      const activatedRoute_queryParams = this._activatedRoute_queryParams()
      untracked(() => {
        this.onRouteChange(routerEvent, activatedRoute_fragment, activatedRoute_queryParams)
      })
    })

    effect(() => {
      const section = this._section()?.toLowerCase()
      const fragments_string = this._fragments()
        .filter(Boolean)
        .join("/")
      const screenName = section + (fragments_string && ("#" + fragments_string))
      // if (section) this.analyticsService.sendScreenViewEvent(screenName, this.constructor.name)
    })
  }

  subscribeToActivatedRoute_queryParams() {
    return this.activatedRoute.queryParams
      .subscribe((queryParams: QueryParams) => {
        this._activatedRoute_queryParams.set(queryParams)
        const debugFromStorage = localStorage.getItem("debug")
        const debug = queryParams.debug !== undefined
          ? queryParams.debug
          : debugFromStorage !== null
            ? debugFromStorage
            : undefined
        if (debug !== undefined) {
          this._debug.set(debug === "true" || debug === "")
          localStorage.setItem("debug", debug)
        }
      })
  }

  changeFragment(fragment: string) {
    // this.changeRoute([], fragment)
  }

  removeFragment() {
    this.changeFragment("")
  }

  changeRoute(route: string[], queryParams?: QueryParams) {
    if (queryParams) {
      this.router
        .navigate(route, { queryParams })
    }
    if (!queryParams) {
      this.router
        .navigate(route)
    }
  }

  viewTimelineDetails(contentId: string) {
    const fragment = "view/" + contentId
    // this.changeRoute([], fragment)
  }

  onRouteChange(routerEvent: RouterEvent | undefined, activatedRouteFragment: string | null | undefined, activatedRoute_queryParams: QueryParams) {
    let section: Section | undefined = undefined
    if (routerEvent) {

      if (location.pathname === "/") {
        this.changeRoute([Section.INTRODUCTION])
      }

      let root = this.router.routerState.snapshot.root
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0]
        } else {
          this._routeHasPageToolbar.set(root.data && root.data["routeHasPageToolbar"])
          break
        }
      }

      /**
       * preserves localMaps #story text field and map marker when navigating back to localMaps from other #paths
       */
      if (location.pathname === "/redline-map" && !location.hash) {
        // this.changeFragment(this.localMapsMode().toLowerCase())
      }

      this._url.set(routerEvent.url)

      const hostnameArray = location.hostname.split(".")
      if (hostnameArray.length === 3) {
        const region = hostnameArray[0]
          .toLowerCase()
          .replace("test-", "")
          .replace("-", "_")
          .toUpperCase() as RegionEnum
        this._region.set(region)
        this.regionIsSet.set(true)
      }
      if (hostnameArray[0] === "localhost") {
        this.regionIsSet.set(true)
      }

      section = location.pathname.replace(/^\//, "").toUpperCase() as Section
/*
      switch (section) {
        case Section.INTRODUCTION:
        case Section.TIMELINE:
          this._section.set(section)
          break
      }
*/
    }

    this._queryParams.set(activatedRoute_queryParams)
    const primaryDialog = activatedRoute_queryParams.p?.toLowerCase()
    const secondaryDialog = activatedRoute_queryParams.s?.toLowerCase()

    const fragments = activatedRouteFragment ? activatedRouteFragment.split("/") : [""]
    while (fragments.length < 4) {
      fragments.push("")
    }
    this._fragments.set(fragments)

    this._underwritingManual.set(fragments[0].toLowerCase() === "underwriting-manual")
    // this._contentManagerModal.set(fragments[0].toLowerCase() === "content-manager")
    // this._contentModeration_dialog.set(fragments[0].toLowerCase() === "content-moderation")

    if (location.pathname.toLowerCase() === "/redline-map") {
      section = Section.REDLINE_MAP
      // this._section.set(Section.REDLINE_MAP)
    }

    /**
     * below is setting both Section and dialog booleans
     * TODO: maybe we can eliminate the dialog booleans, they may be redundant
     */

    let contentManagerDialog = false
    let contentModerationDialog = false
    let myAccountTabIndex = 0

    switch (primaryDialog) {
      case "my-account":
        this.changeRoute([], { p: "my-account-profile" })
        break
      case "my-account-login":
        section = Section.LOGIN
        myAccountTabIndex = 0 // redundant
        break
      case "my-account-profile":
        section = Section.PROFILE
        myAccountTabIndex = 1
        break
      case "my-account-content":
        section = Section.MY_CONTENT
        myAccountTabIndex = 2
        break
      case "content-manager":
        section = Section.MANAGER
        contentManagerDialog = true
        break
      case "content-moderation":
        section = Section.MODERATION
        contentModerationDialog = true
        break
    }

    this._section.set(section)
    this._contentManager_dialog.set(contentManagerDialog)
    this._contentModeration_dialog.set(contentModerationDialog)
    this._myAccountTabIndex.set(myAccountTabIndex)

  }

  subscribeToRouter_events() {
    return this.router.events
      .subscribe(event_2 => {
        if (event_2 instanceof NavigationEnd) {
          this._router_event.set(event_2)
        }
      })
  }

  subscribeToActivatedRoute_fragment() {
    return this.activatedRoute.fragment
      .subscribe(fragment => {
        this._activatedRoute_fragment.set(fragment)
      })
  }

}
