import { Component } from "@angular/core"
import { TourScrollToElementComponent } from "../../../tour/tour-scroll-to-element.component"

@Component({
  imports: [
    TourScrollToElementComponent,
  ],
  standalone: true,
  template: `
    <div style="display: flex; flex-direction: column; gap: 20px">
      <div>
        Redlining turned the false idea of racial hierarchy into legal code and connected it to geography and
        property value, producing very real structural situations that remain today.
      </div>
      <div>
        Federal Redlining is among many policies and practices that deliberately created structural inequality that
        still exists today. Starting with redlining helps us understand how false ideas of racial hierarchy got
        baked into many of our systems and structures. Though policies like redlining were made illegal and formally
        ended, their structural legacies have never been undone.
      </div>
      <e2e-tour-scroll-to-element elementName="what-is-redlining"/>
      <div style="margin-left: -25px; line-height: .9">
        <div style="font-size: 30px; font-weight: 900;">
          WHAT IS
        </div>
        <div style="font-size: 50px; font-weight: 900; color: red">
          REDLINING?
        </div>
      </div>
      <div style="font-weight: 600">
        Federal redlining was rolled out as part of the New Deal. By using explicitly racist language to evaluate
        the creditworthiness of neighborhoods, it led to segregation and disinvestment for people of color, even
        while homeownership built middle-class wealth for White families.
      </div>
      <div>
        As the Federal Government swooped in to save the housing market, they invented the 15 and 30-year mortgage,
        insured home loans and transformed homeownership into a middle-class wealth building tool. This was before you
        or I would have a credit score, and instead they essentially gave everyone’s neighborhood a credit score. There
        were only four types, or ‘grades.’ Everybody got a color: green for the best areas; blue for second best; yellow
        was often referred to as declining; and red was used for ‘hazardous’ areas. Each neighborhood’s rating would
        become crucial for the property value and financial success of that area. Among the most important reasons to
        color an area red was the race of the people living there.
      </div>

    </div>
  `,
  selector: "e2e-intro-definitions",
})
export class IntroDefinitionsComponent {}
