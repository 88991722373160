import { AfterViewInit, Component, computed, ElementRef, inject, OnDestroy, signal, viewChild } from "@angular/core"
import { PageMenuComponent } from "./page-menu.component"
import { environment } from "../../environments/environment"
import { RouterLink } from "@angular/router"
import { ProfileService } from "./profile/profile.service"
import { FirestoreReadService } from "./content/firestore-read.service"
import { ContentType } from "./content/content.model"
import { TourService } from "./tour/tour.service"
import {
  faBars,
  faCircleSmall,
  faEclipse,
  faFolderOpen,
  faHeadSideHeadphones,
  faInbox,
  faInboxFull,
  faMoon,
  faPaperclipVertical,
  faSun,
  faTag,
  faUser,
} from "@fortawesome/pro-solid-svg-icons"
import { PageToolbarService } from "./page-toolbar.service"
import { sort } from "util/sort"
import { PageMenuService } from "./page-menu.service"
import { UiIconComponent } from "ui/icon"
import { RouteService } from "../services/route.service"
import { UiThemeModeSwitcherComponent, UiThemeService } from "ui/theme"
import { UtilBreakpointsService } from "util/breakpoints"
import { HelpTipsComponent } from "feature/redline/help-tips"
import { RegionService } from "../regions/region.service"
import { TourComponent } from "./tour/tour.component"

@Component({
  imports: [
    PageMenuComponent,
    RouterLink,
    UiIconComponent,
    UiThemeModeSwitcherComponent,
    HelpTipsComponent,
    TourComponent,
  ],
  providers: [],
  standalone: true,
  template: `
    @if (showHelpTips()) {
      <lib-feature-help-tips (close)="showHelpTips.set(false)"/>
    }

    <e2e-page-menu/>

    <div
      #pageToolbar
      data-theme="redline_light"
      class="text-primary-content column gap-0"
      style="width: 100%; z-index: 10; position: fixed"
      [style.background-image]="redPaperTexture()"
      (click)="showPageMenu($event)"
    >
      @if (showTour()) {
        <div (click)="$event.stopPropagation()">
          <e2e-tour/>
        </div>
      }
      @if (!showTour()) {
        @if (breakpointMatches().XSmall) {
          <div class="font-bold text-lg center">
            UNDESIGN THE REDLINE
          </div>
        }

        <div
          class="row font-bold text-lg px-2"
          [class]="breakpointMatches().XSmall ? 'pt-0' : ''"
          style="justify-content: space-between"
        >

          <div class="row gap-0">
            <button
              class="btn text-2xl btn-ghost px-2"
              title="Main Menu"
              (click)="showPageMenu($event)"
            >
              <lib-ui-icon [icon]="faBars"/>
            </button>
            @if (audioTour()) {
              <button
                class="btn text-2xl btn-ghost px-2"
                title="Audio Tour"
                (click)="setShowTour(true); $event.stopPropagation()"
              >
                <lib-ui-icon [icon]="faHeadSideHeadphones"/>
              </button>
            }
          </div>

          @if (!breakpointMatches().XSmall) {
            <div [class]="breakpointMatches().Small ? 'text-md' : 'text-2xl'">
              UNDESIGN THE REDLINE
            </div>
          }

          <div
            class="row gap-0"
            (click)="$event.stopPropagation()"
          >

            @if (isEditor()) {
              <button
                class="btn text-2xl btn-ghost px-2"
                title="Content Manager"
                [routerLink]="[]"
                [queryParams]="queryParamsList().contentManager"
              >
                <lib-ui-icon [icon]="faFolderOpen"/>
              </button>
            }

            @if (isModerator()) {
              <button
                class="btn text-2xl btn-ghost px-2 relative"
                title="Moderation"
                [routerLink]="[]"
                [queryParams]="queryParamsList().contentModeration"
              >
                @if (allContributedContent_array().length) {
                  <lib-ui-icon [icon]="faInboxFull"/>
                  <div class="absolute top-0 right-1 text-sm">
                    <lib-ui-icon [icon]="faCircleSmall"/>
                  </div>
                }
                @if (!allContributedContent_array().length) {
                  <lib-ui-icon [icon]="faInbox"/>
                }
              </button>
            }

            @if (loggedIn()) {
              <button
                class="btn text-2xl btn-ghost px-2"
                title="My Stories"
                [routerLink]="[]"
                [queryParams]="queryParamsList().myAccountContent"
              >
                <lib-ui-icon [icon]="faTag"/>
                <!--            <lib-bookmark-->
                <!--              [count]="myContentCount()"-->
                <!--              context="pageTopBar"-->
                <!--            />-->
              </button>
            }
            @if (userInit() && !loggedIn()) {
              <button
                class="btn text-2xl btn-ghost px-2"
                title="My Profile"
                [routerLink]="[]"
                [queryParams]="queryParamsList().myAccountLogin"
              >
                <lib-ui-icon [icon]="faUser"/>
              </button>
            }
            @if (loggedIn()) {
              <button
                class="btn text-2xl btn-ghost px-2"
                title="My Profile"
                [routerLink]="[]"
                [queryParams]="queryParamsList().myAccountProfile"
              >
                @if (profileImage()) {
                  <div class="w-6 aspect-square">
                    <img
                      [src]="imageSrc()"
                      [alt]="userName()"
                      style="clip-path: circle(50%); object-fit: cover; height: 100%"
                    />
                  </div>
                } @else {
                  <lib-ui-icon [icon]="faUser"/>
                }

              </button>
            }
            <lib-ui-theme-mode-switcher buttonClasses="btn-ghost text-2xl px-2"/>

            <button
              class="btn text-2xl btn-ghost px-2"
              title="Guide/Info"
              (click)="showHelpTips.set(!showHelpTips())"
            >
              <lib-ui-icon
                [icon]="faPaperclipVertical"
              />
              <!--            [animation]="helpTipsAnimation()"-->
            </button>

            <!--
                      <button
                        class="btn text-2xl btn-ghost px-2"
                        title="Save All"
                        (click)="saveAllContent()"
                      >
                        <lib-ui-icon
                          [icon]="faPaperclipVertical"
                          [animation]="helpTipsAnimation()"
                        />
                      </button>
            -->

          </div>

        </div>
      }
    </div>
  `,
  selector: "e2e-page-toolbar",
})
export class PageToolbarComponent implements AfterViewInit, OnDestroy {
  private profileService = inject(ProfileService)
  private firestoreReadService = inject(FirestoreReadService)
  private tourService = inject(TourService)
  private pageToolbarService = inject(PageToolbarService)
  private pageMenuService = inject(PageMenuService)
  private themeService = inject(UiThemeService)
  private routeService = inject(RouteService)
  private breakpointService = inject(UtilBreakpointsService)
  private regionService = inject(RegionService)

  audioTour = computed(() => this.regionService.config()?.audioTour)

  queryParamsList = this.routeService.queryParamsList

  toolbarElement = viewChild.required<ElementRef<HTMLElement>>("pageToolbar")
  redPaperTexture = signal("url('" + environment.imagekit_urlEndpoint + "/assets/images/red-paper-texture.png')")

  isEditor = this.profileService.isEditor
  isModerator = this.profileService.isModerator
  userInit = this.profileService.userInit
  loggedIn = this.profileService.loggedIn
  userName = this.profileService.userName
  image = this.profileService.profileImage

  showHelpTips = signal(false)
  helpTipsAnimation = computed(() => this.showHelpTips() ? undefined : "bounce")

  breakpointMatches = this.breakpointService.breakpointMatches

  allContributedContent_array = computed(() =>
    this.firestoreReadService
      .arrayFromContent()
      .filter((content) => content.status.contentTypes.includes(ContentType.CONTRIBUTED))
      .sort((a, b) => sort(a.settings.title, b.settings.title)),
  )
  myContributedContent_array = computed(() =>
    this.allContributedContent_array().filter(
      (content) => content.userActions.created[0].userId === this.profileService.userId(),
    ),
  )
  myContentCount = computed(
    () =>
      this.firestoreReadService
        .arrayFromContent()
        .filter((content) => content.userActions.created[0].userId === this.profileService.userId()).length,
  )
  profileImage = computed(() => this.profileService.profile()?.profileImage)
  imageSrc = computed(() => {
    const image = this.profileImage()
    return (
      (image &&
        [
          "https://storage.googleapis.com/",
          environment.firebaseConfig.storageBucket,
          "/",
          image.filePath,
          "?",
          image.lastUpdated.seconds,
        ].join("")) ||
      ""
    )
  })

  showTour = this.tourService.showTour
  showMenu = this.pageMenuService.showMenu

  private resizeObserver: ResizeObserver | undefined

  constructor() {
    this.themeService.setThemeNames(["auto", "redline_light", "redline_dark"])
    this.themeService.setThemeIcons([faEclipse, faSun, faMoon])
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        this.pageToolbarService.pageToolbarHeight.set(entry.contentRect.height)
      }
    })
    this.resizeObserver.observe(this.toolbarElement().nativeElement)
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect()
  }

  // interactiveMode$ = this.store.select(AppStore.interactiveMode)
  // interactiveModeValue$ = this.store.select(AppStore.interactiveModeValue)

  /*
    changeInteractiveMode() {
      this.store.dispatch(AppStore.TimelineActions.toggleInteractiveMode())
    }
  */

  /*
    @HostListener("window:resize")
    onResize(): void {
      const height = this.pageToolbar?.nativeElement.getBoundingClientRect().height
      const width = this.pageToolbar?.nativeElement.getBoundingClientRect().width
      if (height && height !== this.globalState.get("pageToolbarHeight")) {
        this.globalState.set("pageToolbarHeight", () => height)
      }
      if (width && width !== this.globalState.get("pageWidth")) {
        this.globalState.set("pageWidth", () => width)
      }
    }
  */

  setShowTour(showTour: boolean) {
    this.tourService.setShowTour(showTour)
  }

  showPageMenu(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    this.pageMenuService.toggleMenu()
  }

  saving = false

/*
  saveAllContent() {
    if (!this.saving) {
      this.firestoreWriteService.saveContent(Array.from(this.firestoreReadService.content_map().values()))
        .then(() => {
          console.log("batch saved")
        })

      this.saving = true
    }
  }
*/

  protected readonly faHeadSideHeadphones = faHeadSideHeadphones
  protected readonly faUser = faUser
  protected readonly faFolderOpen = faFolderOpen
  protected readonly faInbox = faInbox
  protected readonly faInboxFull = faInboxFull
  protected readonly faBars = faBars
  protected readonly faTag = faTag
  protected readonly faPaperclipVertical = faPaperclipVertical
  protected readonly faCircleSmall = faCircleSmall
}
