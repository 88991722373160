import { Section } from "../../services/route.service"

export type TourDoc = {
  items: TourItem[]
}

export enum AudioStatus {
  playing = "playing",
  pause = "pause"
}

export enum TourItemId {
  INTRO_R1 = "INTRO_R1",
  INTRO_R2 = "INTRO_R2",
  S1_R1 = "S1_R1",
  S1_R2 = "S1_R2",
  S1_R3 = "S1_R3",
  S1_R4 = "S1_R4",
  S1_R5 = "S1_R5",
  S1_R6 = "S1_R6",
  S2_R1 = "S2_R1",
  S2_R2 = "S2_R2",
  S2_R3 = "S2_R3",
  S2_R4 = "S2_R4",
  S3_R1 = "S3_R1",
  S3_R2 = "S3_R2",
  S3_R3 = "S3_R3",
  S3_R4 = "S3_R4",
  S3_R5 = "S3_R5",
  S3_R6 = "S3_R6",
  S3_R7 = "S3_R7",
  S3_R8 = "S3_R8",
  S3_R9 = "S3_R9",
  S3_R10 = "S3_R10",
  S4_R1 = "S4_R1",
  S5_R1 = "S5_R1",
  S5_R2 = "S5_R2",
}

export type TourItem = {
  id: string
  fileName?: string
  sectionTitle: string
  title: string
  segments: TourItemSegment[]
}

export type TourItemSegment = {
    description: string
    timestamp: number
    section: Section
    anchor: string
    contentId: string
    zoomLevel: number
    offsetX: number
    offsetY: number
}

// export enum Section {
//   S1 = "Introduction",
//   S2 = "Redlining",
//   S3 = "Timeline",
//   S4 = "Stories",
//   S5 = "Undesign",
// }








export enum SectionTitle {
  S1 = "Introduction",
  S2 = "Redlining",
  S3 = "Timeline",
  S4 = "Stories",
  S5 = "Undesign",
}

// export const tourItemsMock: TourItem[] = [
//   {
//     id: TourItemId.INTRO_R1,
//     sectionTitle: SectionTitle.S1,
//     title: "This is a virtual version of a traveling exhibit",
//     segments: []
//   },
//   {
//     id: TourItemId.INTRO_R2,
//     sectionTitle: SectionTitle.S1,
//     title: "Learn more",
//     segments: []
//   },
//   {
//     id: TourItemId.S1_R1,
//     sectionTitle: SectionTitle.S1,
//     title: "Back to the 1930s",
//     segments: [
//       {
//         description: "Credit system for neighborhoods",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S1_R2,
//     sectionTitle: SectionTitle.S1,
//     title: "Red areas were cut off",
//     segments: [
//       {
//         description: "Vibrant neighborhoods declined",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S1_R3,
//     sectionTitle: SectionTitle.S1,
//     title: "Why? \"Negro Infiltration\"",
//     segments: []
//   },
//   {
//     id: TourItemId.S1_R4,
//     sectionTitle: SectionTitle.S1,
//     title: "Meanwhile green areas \"homogeneous\" largely in suburbs",
//     segments: [
//       {
//         description: "Wealthier, whiter suburbs, concentrated poverty in cities, mostly people of color",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S1_R5,
//     sectionTitle: SectionTitle.S1,
//     title: "Fast forward to today - different systems for different people",
//     segments: [
//       {
//         description: "This is why we think redlining is so important to understand, how do we go from explicit jim crow era racism, to structural racism",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//       {
//         description: "Redlining is part of what designed racism and inequality into our cities and systems, it has never been undone - we still live in the world redlining created.",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S1_R6,
//     sectionTitle: SectionTitle.S1,
//     title: "Interact prompt – click on the other zones and the 'impacts for today' to learn",
//     segments: []
//   },
//   {
//     id: TourItemId.S2_R1,
//     sectionTitle: SectionTitle.S2,
//     title: "Explore the local map (location - at map)",
//     segments: [
//
//       {
//         description: "SEGMENT 1",
//         timestamp: 0,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//
//       {
//         description: "SEGMENT 2",
//         timestamp: 15,
//         section: Section.S1,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//
//     ]
//   },
//   {
//     id: TourItemId.S2_R2,
//     sectionTitle: SectionTitle.S2,
//     title: "Underwriting Manual (location - underwriting manual image) prompt to interact? Or this pop out just goes on its own",
//     segments: [
//       {
//         description: "Can discuss this more for clarity",
//         timestamp: 0,
//         section: Section.S2,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S2_R3,
//     sectionTitle: SectionTitle.S2,
//     title: "NAREB quote (location - nareb quote)",
//     segments: []
//   },
//   {
//     id: TourItemId.S2_R4,
//     sectionTitle: SectionTitle.S2,
//     title: "So we’ve learned about redlining (Redlining timeline? - animation to s3?)",
//     segments: []
//   },
//   {
//     id: TourItemId.S3_R1,
//     sectionTitle: SectionTitle.S3,
//     title: "How to read the timeline (zoomed out to full timeline)",
//     segments: []
//   },
//   {
//     id: TourItemId.S3_R2,
//     sectionTitle: SectionTitle.S3,
//     title: "We begin the timeline… (Section 1, top)",
//     segments: [
//       {
//         description: "Zoom to american progress",
//         timestamp: 0,
//         section: Section.S2,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//       {
//         description: "Zoom to reconstruction",
//         timestamp: 0,
//         section: Section.S2,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//       {
//         description: "Zoom back out / jim crow",
//         timestamp: 0,
//         section: Section.S2,
//         anchor: "",
//         contentId: "",
//         zoomLevel: 1,
//         offsetX: 0,
//         offsetY: 0
//       },
//     ]
//   },
//   {
//     id: TourItemId.S3_R3,
//     sectionTitle: SectionTitle.S3,
//     title: "When we look at the bottom up side of the timeline (Section 1, bottom)",
//     segments: []
//   },
// ]
