import { Component, computed, effect, ElementRef, inject, input, viewChild } from "@angular/core"
import { TourService } from "./tour.service"
import { PageToolbarService } from "../page-toolbar.service"

@Component({
  imports: [],
  standalone: true,
  template: `
      <div
        #scrollToElement
        [style.scroll-margin-top.px]="scrollMarginTop()"
      ></div>
  `,
  selector: "e2e-tour-scroll-to-element",
})
export class TourScrollToElementComponent {
  private tourService = inject(TourService)
  private pageToolbarService = inject(PageToolbarService)

  elementName = input.required<string>()

  elementRef = viewChild<ElementRef>("scrollToElement")
  pageToolbarHeight = this.pageToolbarService.pageToolbarHeight
  scrollMarginTop = computed(() => this.pageToolbarHeight() + 10)

  constructor() {
    effect(() => {
      const elementName = this.tourService.scrollToElementName()
      if (elementName === this.elementName()) {
        this.scrollToElement()
        // Reset the signal to prevent repeated triggering
        this.tourService.scrollToElement(false)
      }
    })
  }

  scrollToElement() {
    const element = this.elementRef()?.nativeElement
    if (element) {
      setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 50)
    }
  }

}
